.footer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background-color: rgb(49, 49, 49);
    color: #ffffff;
}

.footer-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer-column{
    width: 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.icons-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:left;
}

.icon{
    color: #ffffff;
    object-fit: contain;
    width: 40px; 
    height: 40px; 
    transform: scale(1);
    margin-right: 20px;
}

.icon:last-child {
    margin-right: 0;
}

.website-rights {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: left;
    width: 100%;
    color: #ffffff;
    margin-top: 10px;
    margin-bottom: 0; 
}

@media screen and (max-width: 920px) {
    .footer-row{
        flex-direction: column;
    }

    .footer-column{
        width: 100%;
    }
}