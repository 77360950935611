.proj-custom-link {
    color: inherit;
    text-decoration: none;
    transition: all 0.5s ease-out;
}


.proj-card-container {
    width: 900px;
    height: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin: 0;
    padding: 0;
    transition: all 0.5s ease-out;
    overflow: hidden;
}

.proj-card-image {
    width: 300px;
    height: 300px;
    margin-top: 0px;
    object-fit: cover;
    border-top-left-radius: 25px;
}



.proj-skill-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.proj-text-container {
    background-color: rgb(255, 255, 255);
    height: 300px;
    width: 600px;
    border-bottom-right-radius: 25px;
    display: flex-start;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.proj-text-container h1 {
    margin-top: 10px;
    font-size: 32px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    text-align: left;
    padding-left: 40px;
    padding-right: 40px;
}

.proj-text-container p {
    margin-top: 10px;
    font-size: 16px;
    color: rgb(0, 0, 0);
    text-align: left;
    padding-left: 40px;
    padding-right: 40px;
}


.proj-skill-item {
    height: 80px;
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    margin-right: 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease-out;
}

.proj-skill-item:hover {
    transform: scale(1.1);
    transition: all 0.5s ease-out;
}

#q-mark-icon {
    border-radius: 10px;
}

@media only screen and (max-width: 960px) {

    .proj-card-container {
        width: 100%;
        flex-direction: column;
        align-items: center;
            height: auto; /* Allow the height to adjust as necessary */
    }

    .proj-card-image {
        width: 80%;
        height: 250px;
    }


    .proj-text-container {
        width: 80%;
        height: auto;
        margin: 0;
        padding: 0;
    }

.skills-title {
    display: none;
}

    .proj-skill-container {
        padding: 0px;
        margin-bottom: 20px;
        flex-wrap: wrap;    
        display: none;
    }

    .proj-text-container h1 {
        margin-top: 10px;
        font-weight: bold;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .proj-text-container p {
        margin-top: 10px;
        font-size: 16px;
        color: rgb(0, 0, 0);
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

}

/* 
@media only screen and (max-width: 700px) {
    .proj-card-container {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
    }

    .proj-card-image {
        width: 100%;
        height: 300px;
    }

    .proj-text-container {
        width: 100%;
        height: 620px;
        margin: 0;
        padding: 0;
    }
}



@media only screen and (max-width: 570px) {
    .proj-card-container {
        margin: 0;
    }
}

@media only screen and (max-width: 460px) {
    .proj-card-container {
        margin: 0;
    }
} */