.LinkCard-container {
    width: 200px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin: 20px;
    transition: all 0.5s ease-out;
}

.LinkCard-container:hover {
transform: scale(1.1);
transition: all 0.5s ease-out;

}


.LinkCard-image {
    width: 200px; /* Use 100% width to ensure responsiveness */
    height: 200px;
    margin-top: 0px;
    object-fit: cover; /* Maintain aspect ratio while covering the container */
    border-top-left-radius: 25px; /* Apply border-radius to the image */
    align-self: flex-start; /* Align items to the top of the container */
    justify-self: flex-start; /* Align items to the top of the container */
}

.text-container > h1 {
 margin-top: 10px; /* Add some spacing between the image and the title */
 font-size: 18px;
 padding: 5px;
    color: rgb(0, 0, 0);
    text-align: center; /* Center the text */
}

.text-container {
    background-color: rgb(255, 255, 255);
    height: 100px;
    width: 200px;
    border-bottom-right-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

 .custom-link {
    color: inherit;
    text-decoration: none;
    transition: all 0.5s ease-out;
  }

