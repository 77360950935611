.proj-cards-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 0;
}


