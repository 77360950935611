/* Improved styling for the cards container */

.container-background {
height: 600px;
width: auto;
background-color: whitesmoke;
align-items: center;
justify-content: center;
margin: auto; /* Centered the container with auto margins */
overflow: hidden; /* Ensured content overflow is hidden */
text-align: center;
}

.cards-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Adjusted box-shadow for a subtle effect */
    color: rgb(49, 49, 49);
    max-width:max-content; /* Added max-width to limit container width */
    height: 350px;
    border-radius: 20px;
    margin: 20px auto; /* Centered the container with auto margins */
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Hide vertical scrollbar */
  }

  .container-background > h1 {
padding: 30px;
font-size: 60px;
color: rgb(0, 0, 0);
text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Add a media query for smaller screens */
@media only screen and (max-width: 960px) {
  .cards-container {
    flex-wrap: wrap; /* Allow cards to wrap onto the next line */
    height: max-content;
    justify-content:center; /* Distribute cards evenly */
  }
  
  .container-background {
    flex-wrap: wrap; /* Allow cards to wrap onto the next line */
    height: max-content;
  }
}